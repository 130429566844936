interface NotificationMessage {
  title: string;
  text: string;
  state: string;
}

class NotificationManager {
  /**
   * Prevent a dom element from having more than n child elements
   * @param parentElement
   * @param n
   * @private
   */
  private keepNChildElements(parentElement: HTMLElement, n: number) {
    const children = parentElement.children;
    while (children.length > n) {
      parentElement.removeChild(children[n]);
    }
  }

  /**
   * Build a new DOM element for a notification message
   * @param data
   * @private
   */
  private buildDomForMessage(data: NotificationMessage) {
    const template = document.createElement('template');
    template.innerHTML = `
    <li class="notif-item is-unread">
      <div class="notif-infos">
        <span class="notif-title ${data.state}">
          ${data.title}
        </span>

        <p class="notif-metas">
          ${data.text}
        </p>
      </div>
      <p class="notif-when">À l'instant</p>
    </li>`;

    return template.content.children[0];
  }

  /**
   * Display a dot when a new notification is available
   * @private
   */
  private triggerNotificationIcon() {
    document.querySelectorAll('.notif-available').forEach((icon) => {
      icon.classList.remove('d-none');
    });
  }

  /**
   * Action executed when receiving a new message
   * @param event
   * @private
   */
  private onMessage(event: MessageEvent<string>) {
    const message = JSON.parse(event.data) as NotificationMessage;
    const dom = this.buildDomForMessage(message);
    const list = document.getElementById('nav-notifs-list') as HTMLTemplateElement;
    list.insertBefore(dom, list.firstChild);
    this.keepNChildElements(list, 3);
    this.triggerNotificationIcon();
  }

  /**
   * Get the url to connect to
   * @private
   */
  private getUrl(): string {
    const userId: string = window.__userId__ as string;
    return `/events/${userId}/`;
  }

  /**
   * Initialize EventSource connexion
   */
  public initialize() {
    const userId: string = window.__userId__ as string;
    if (userId === 'None') {
      return;
    }

    const eventSource = new EventSource(this.getUrl());
    eventSource.onmessage = (event: MessageEvent<string>) => this.onMessage(event);
    eventSource.onerror = (event) => {
      console.error(event);
    };

    window.addEventListener('beforeunload', () => {
      // Disconnect when closing the window
      eventSource.close();
    });
  }
}

document.addEventListener('DOMContentLoaded', () => {
  const notification = new NotificationManager();
  notification.initialize();
});
