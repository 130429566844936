import { Notyf } from 'notyf';

export class Toast {
  private instance: Notyf | null = null;

  private getInstance() {
    if (!this.instance) {
      this.instance = this.createInstance();
    }

    return this.instance;
  }

  private createInstance() {
    return new Notyf({
      duration: 10000,
    });
  }

  public success(payload: string) {
    this.getInstance().success(payload);
  }

  public error(payload: string) {
    this.getInstance().error(payload);
  }
}
