import { FetchWrapper } from './fetch';

class LotLoader {
  /**
   * Fetch the lot's option form from the API
   * @param id
   */
  async loadLotOffCanvas(id: number) {
    const offCanvas = document.querySelector('#offcanvas-option');

    if (!offCanvas) {
      return;
    }

    // Load data from API
    const response = await fetch(`/api/lots/${id}/option_form/`, {
      headers: {
        accept: 'text/html',
      },
    });

    offCanvas.innerHTML = await response.text();
  }

  /**
   * What to do when an option has been added
   */
  onOptionAdded() {
    document.querySelector('#offcanvas-option-lot')?.classList.add('d-none');
    document.querySelector('#option-success-message')?.classList.remove('d-none');
  }

  /**
   * Save an option using the API
   * @param lotId
   * @param prospectId
   */
  async addOption(lotId: number, prospectId: number) {
    const f = new FetchWrapper();
    await f.postJson(
      `/api/options/`,
      {
        prospect: prospectId,
        lot: lotId,
      },
      {
        successCallback: () => this.onOptionAdded(),
      },
    );
  }

  /**
   * Bind events on the "Poser une option" buttons
   */
  addLotsEventListener() {
    const optionButtons = document.querySelectorAll('[data-action="display-current-lot"]');

    // Iterate through each button and add event listener
    optionButtons.forEach((button) => {
      button.addEventListener('click', () => {
        void (async () => {
          // Extract the ID from the button's dataset
          const id = (button as HTMLButtonElement).dataset.id;

          if (id) {
            await this.loadLotOffCanvas(parseInt(id, 10));
            this.addOptionFormEventListener();
          }
        })();
      });
    });
  }

  /**
   * Bind an event when submitting the lot's option form
   */
  addOptionFormEventListener() {
    const form = document.querySelector('#offcanvas-option form');

    if (!form) {
      return;
    }

    form.addEventListener('submit', (event) => {
      event.preventDefault();

      if (!(event.target as HTMLFormElement).checkValidity()) {
        return;
      }

      const lotId = (document.getElementById('lot') as HTMLInputElement)?.value;
      const prospectId = (document.getElementById('prospect') as HTMLSelectElement)?.value;

      void (async () => {
        await this.addOption(parseInt(lotId, 10), parseInt(prospectId));
      })();
    });
  }

  initialize() {
    this.addLotsEventListener();
  }
}

document.addEventListener('DOMContentLoaded', () => {
  const lotLoader = new LotLoader();
  lotLoader.initialize();
});
