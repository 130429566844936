import { Toast } from './toast';

export type ErrorResponse = Readonly<Record<string, string[]>>;

export class FetchError extends Error {
  public response: Response;
  public status: number;
  public toast = new Toast();

  constructor(response: Response) {
    super();

    this.response = response;
    this.status = response.status;
  }

  async notify() {
    try {
      const errors = (await this.response.json()) as ErrorResponse;

      const messages: string[] = Object.entries(errors).map((error) => {
        if (error[0] === 'non_field_errors') {
          return `Erreur : ${error[1].map((message) => message).join('. ')}`;
        } else {
          return `Erreur sur le champ ${error[0]} : ${error[1].map((message) => message).join('. ')}`;
        }
      });

      for (const message of messages) {
        this.toast.error(message);
      }
    } catch (e) {
      console.error(e);
    }
  }
}

interface FetchWrapperOptions {
  successMessage?: string;
  successCallback?: () => void;
}

export class FetchWrapper {
  public toast = new Toast();

  async postJson<T>(
    url: string,
    data: Record<string, unknown>,
    options?: FetchWrapperOptions,
  ): Promise<T | undefined> {
    try {
      const csrfToken = document.getElementsByName('csrfmiddlewaretoken');

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          'X-CSRFToken': (csrfToken[0] as HTMLInputElement).value,
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new FetchError(response);
      }

      if (options && options.successMessage) {
        this.toast.success(options.successMessage);
      }

      if (options && options.successCallback) {
        options.successCallback();
      }

      return (await response.json()) as T;
    } catch (error) {
      if (error instanceof FetchError) {
        await error.notify();
      }
    }
  }
}
