document.addEventListener('DOMContentLoaded', () => {
  const togglePasswordButton = document.querySelector('#id_password + .btn-input') as HTMLButtonElement;
  const passwordInput = document.getElementById('id_password') as HTMLInputElement;

  if (togglePasswordButton && passwordInput) {
    togglePasswordButton.addEventListener('click', (e) => {
      e.preventDefault();
      const type = passwordInput.getAttribute('type') === 'password' ? 'text' : 'password';
      passwordInput.setAttribute('type', type);
    });
  }
});
