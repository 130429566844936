import { FetchWrapper } from './fetch';

class ProspectManager {
  /**
   * Fetch the prospect's resume from the API
   * @param id
   */
  async loadProspectOffCanvas(id: number) {
    const offCanvas = document.querySelector('#resume');

    if (!offCanvas) {
      return;
    }

    // Load data from API
    const response = await fetch(`/api/prospects/${id}/resume/`, {
      headers: {
        accept: 'text/html',
      },
    });

    offCanvas.innerHTML = await response.text();
  }

  /**
   * What to do when an option has been added
   */
  onProspectAdded() {
    document.querySelector('#offcanvas-prospect')?.classList.add('d-none');

    const prospectForm = document.querySelector('#offcanvas-prospect form') as HTMLFormElement;
    prospectForm.reset();

    document.querySelector('#prospect-success-message')?.classList.remove('d-none');
  }

  /**
   * Save a prospect using the API
   * @param firstname
   * @param lastname
   * @param address
   * @param zip_code
   * @param town
   * @param email
   * @param phone
   * @param program
   */
  async addProspect(
    firstname: string,
    lastname: string,
    address: string,
    zip_code: string,
    town: string,
    email: string,
    phone: string,
    program: number,
  ) {
    const f = new FetchWrapper();
    await f.postJson(
      `/api/prospects/`,
      {
        firstname,
        lastname,
        address,
        zip_code,
        town,
        email,
        phone,
        program,
      },
      {
        successCallback: () => this.onProspectAdded(),
      },
    );
  }

  /**
   * Bind an event when submitting the prospect's option form
   */
  addFormEventListener() {
    const form = document.querySelector('#offcanvas-prospect-add form');

    if (!form) {
      return;
    }

    form.addEventListener('submit', (event) => {
      event.preventDefault();

      if (!(event.target as HTMLFormElement).checkValidity()) {
        return;
      }

      const firstname = (document.getElementById('firstname') as HTMLInputElement)?.value;
      const lastname = (document.getElementById('lastname') as HTMLInputElement)?.value;
      const address = (document.getElementById('address') as HTMLInputElement)?.value;
      const zip_code = (document.getElementById('zip_code') as HTMLInputElement)?.value;
      const town = (document.getElementById('town') as HTMLInputElement)?.value;
      const email = (document.getElementById('email') as HTMLInputElement)?.value;
      const phone = (document.getElementById('phone') as HTMLInputElement)?.value;
      const program = parseInt((document.getElementById('program') as HTMLInputElement)?.value, 10);

      void (async () => {
        await this.addProspect(firstname, lastname, address, zip_code, town, email, phone, program);
      })();
    });
  }

  addOffCanvasEventListener() {
    const prospectButtons = document.querySelectorAll('[data-action="display-current-prospect"]');

    // Iterate through each button and add event listener
    prospectButtons.forEach((button) => {
      button.addEventListener('click', () => {
        void (async () => {
          // Extract the ID from the button's dataset
          const id = (button as HTMLButtonElement).dataset.id;

          if (id) {
            await this.loadProspectOffCanvas(parseInt(id, 10));
          }
        })();
      });
    });

    const prospectOffCanvas = document.getElementById('offcanvas-prospect-add');
    if (prospectOffCanvas) {
      prospectOffCanvas.addEventListener('hidden.bs.offcanvas', function () {
        const offcanvasProspect = document.querySelector('#offcanvas-prospect');

        // Reload the page only when closing the prospect offcanvas after a prospect creation
        if (offcanvasProspect?.classList.contains('d-none')) {
          offcanvasProspect?.classList.remove('d-none');
          document.querySelector('#prospect-success-message')?.classList.add('d-none');
          window.location.reload();
        }
      });
    }
  }

  initialize() {
    this.addFormEventListener();
    this.addOffCanvasEventListener();
  }
}

document.addEventListener('DOMContentLoaded', () => {
  const prospectLoader = new ProspectManager();
  prospectLoader.initialize();
});
